import { clsx } from 'clsx';

import type { LoadingLayerProps } from './type';

import { IconLoading } from '~/components/icons';
import { useLoadingWithBuffer } from '~/hooks/utils';

export const LoadingLayer = ({ loading }: LoadingLayerProps) => {
  // ちらつき防止
  const loadingWithBuffer = useLoadingWithBuffer(loading);
  if (!loadingWithBuffer) return null;

  return (
    <div
      className={clsx(
        'tw-absolute tw-inset-0 tw-z-tooltip tw-flex tw-w-full tw-items-center tw-justify-center tw-bg-white/60'
      )}
    >
      <IconLoading boxSize="40px" />
    </div>
  );
};
