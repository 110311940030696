import { useEffect, useState } from 'react';

// 指定したbuffer時間(ms)だけloadingを持続させる
export const useLoadingWithBuffer = (
  defaultLoading: boolean,
  buffer: number = 300
) => {
  const [isLoading, setIsLoading] = useState(defaultLoading);
  const [isLocking, setIsLocking] = useState(false);

  useEffect(() => {
    if (defaultLoading) {
      setIsLoading(true);
      setIsLocking(true);
      setTimeout(() => setIsLocking(false), buffer);
    }

    if (!defaultLoading && !isLocking) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLoading, isLocking]);

  return isLoading;
};
